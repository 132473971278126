<template>
    <section
        class="hero-section background-img ptb-100"
        style="
            background: url('img/particle.svg') no-repeat center center / cover;
        "
    >
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7 col-lg-6">
                    <div
                        class="position-relative mt-lg-0 mt-md-0 mt-5 text-white"
                    >
                        <h3 class="text-white">Black Friday Sale!</h3>
                        <h1 class="text-white big-text mb-0">
                            <span>UP TO</span> 90% OFF
                        </h1>
                        <p class="lead">
                            Limited time offer download and updated our app,
                            synthesize accurate users whereas communities
                            assertively evolve technically sound whereas
                            real-time materials.
                        </p>
                        <a href="#" class="btn google-play-btn"
                            >Get Started Now</a
                        >
                    </div>
                </div>
                <div class="col-md-5 col-lg-5">
                    <div class="offer-tag-wrap position-relative z-index">
                        <img
                            src="img/offer-single-img.png"
                            alt="app"
                            class="img-fluid"
                        />
                        <div class="offer-tag">
                            <div class="ribbon-2">
                                <span>Start From</span>
                            </div>
                            <div class="offer-price">
                                <span>$</span>0.99<small>/Mo</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Banner",
};
</script>

<style>
</style>