<template>
    <section class="promo-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>
                           How Do You get Started With <br />Trybe?
                        </h2>
                        <p class="lead">
                            Join by following these simple steps
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-vector text-white"></span>
                        </div>
                        <h5>Create your Trybe</h5>
                        <p>
                            Create your accout then proceed to either registering or joining a Trybe.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-lock text-white"></span>
                        </div>
                        <h5>Invite Your Friends</h5>
                        <p>
                           Create your saving network by inviting friends & family.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-eye text-white"></span>
                        </div>
                        <h5>Start Contributing</h5>
                        <p>
                           Start collecting contributions & manage them from your dashboard.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Promo",
};
</script>