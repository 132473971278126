<template>
  <section class="team-member-section ptb-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-8">
          <div class="section-heading text-center mb-4">
            <h2>Meet Our team members</h2>
            <p class="lead">For every great product is an awesome team.</p>
            <p class="lead">This is our Trybe!</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6" v-for="team in teams" :key="team.name">
          <div
            class="single-team-member position-relative my-lg-3 my-md-3 my-sm-0"
          >
            <div class="team-image">
              <img
                src="img/team-4.jpg"
                alt="Team Members"
                class="img-fluid rounded"
              />
            </div>
            <div
              class="team-info text-white rounded d-flex flex-column align-items-center justify-content-center"
            >
              <h5 class="mb-0">{{ team.name }} </h5>
              <h6> {{ team.role }}  </h6>
              <ul class="list-inline team-social social-icon my-4 text-white">
                <li class="list-inline-item" v-for="social in team.social_medias" :key="social.name">
                  <a :href="social.url" target="_blank"><span :class="social.icon"></span></a>
                </li>
              
              </ul>
              <a href="team-2" target="_blank" class="btn app-store-btn"
                >View details</a
              >
            </div>
          </div>
        </div>
      
      
       
      
        
      </div>
    </div>
  </section>
</template>

<script>
import teamJson from "./TeamMembers.json";

export default {
  name: "TeamMember",
  data() {
    return {
      teams: teamJson,
    };
  },
  methods: {},
  created() {},
};
</script>

<style>
</style>