<template>
    <section id="pricing" class="package-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Pricing Packages</h2>
                        <p class="lead">
                            Monotonectally grow strategic process improvements
                            vis-a-vis integrated resources.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md">
                    <div class="card text-center single-pricing-pack">
                        <div class="card-header py-5 border-0 pricing-header">
                            <div class="h1 text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >29</span
                                >
                            </div>
                            <span class="h6 text-muted">Basic License</span>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search &amp; SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>2 months technical support</li>
                                <li>2+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn solid-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md">
                    <div
                        class="card primary-bg text-center single-pricing-pack"
                    >
                        <div class="card-header py-5 border-0 pricing-header">
                            <div class="h1 text-white text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >149</span
                                >
                            </div>
                            <span class="h6 text-white">Extended License</span>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-white text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search &amp; SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>1 Year technical support</li>
                                <li>50+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn app-store-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md">
                    <div class="card text-center single-pricing-pack">
                        <div class="card-header py-5 border-0 pricing-header">
                            <div class="h1 text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >39</span
                                >
                            </div>
                            <span class="h6 text-muted">Standard License</span>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search &amp; SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>6 months technical support</li>
                                <li>10+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn solid-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center">
                <p class="mb-2">
                    If you need custom services or Need more?
                    <a href="#" class="color-secondary"> Contact us </a>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Pricing",
};
</script>

<style>
</style>