<template>
    <section class="download-section pt-100 background-img download-1-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="download-content text-white pb-100">
                        <h2 class="text-white">
                            One App for all your needs 
                        </h2>
                        <p class="lead">
                            The Trybe App is equipped with all the features you will 
                            ever need to run your Cooperative & Adashi effectviely.
                        
                       
                            Our team is constantly working to include more features to serve you better.
                    
                        </p>

                        <div class="download-btn">
                            <a href="#" class="btn google-play-btn mr-3"
                                ><span class="ti-android"></span> Google Play</a
                            >
                            &nbsp;
                            <a href="#" class="btn app-store-btn"
                                ><span class="ti-apple"></span> App Store</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="download-img d-flex align-items-end">
                        <img
                            src="img/app-hand-top.png"
                            alt="download"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Download",
};
</script>

<style>
.download-1-bg {
    background: url("../../assets/img/app-hero-bg.jpg") no-repeat center center /
        cover !important;
}
</style>