<template>
    <section class="hero-section pt-100 background-img banner-1-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between py-5">
                <div class="col-md-7 col-lg-6">
                    <div class="hero-content-left text-white">
                        <h1 class="text-white">
                            <span>Manage your Cooperative & Adashi Easily</span>
                        </h1>
                        <p class="lead">
                           The only Social Saving Solution you will ever need.
                           One App to manage all your Adashi & Cooperative needs.
                        </p>

                        <a href="/download" class="btn app-store-btn"
                            >Download Now</a
                        >

                      

                        <div
                            class="video-promo-content py-4 d-flex align-items-center"
                        >
                            <a
                                href="#"
                                @click="showYouTubeModal"
                                class="popup-youtube video-play-icon-without-bip video-play-icon mr-3"
                                ><span class="ti-control-play"></span
                            ></a>
                            Watch Video Overview
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-lg-5">
                    <div class="hero-animation-img">
                        <img
                            src="img/app-product.png"
                            width="1200px"
                            height="1503px"
                            alt="app"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-img-absolute">
            <img
                src="img/hero-bg-shape-1.svg"
                alt="wave shape"
                class="img-fluid"
            />
        </div>
        <YTModal
            url="https://www.youtube.com/watch?v=KxWsltWc898"
            v-if="showModal"
            @close="showModal = false"
        >
            Introductory Video
        </YTModal>
    </section>
</template>

<script>
import YTModal from "../../components/YTModal";
export default {
    name: "Banner",
    components: { YTModal },
    data: function () {
        return {
            showModal: false,
        };
    },
    methods: {
        showYouTubeModal: function (e) {
            e.preventDefault();
            this.showModal = true;
        },
    },
};
</script>

<style>
.banner-1-bg {
    background: url("../../assets/img/app-hero-bg.jpg") no-repeat center center /
        cover !important;
}
</style>