<template>
    <div class="overflow-hidden">
        <section
            id="pricing"
            class="package-section background-shape-right ptb-100"
        >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section-heading text-center mb-5">
                            <h2>
                               FAQs
                            </h2>
                            <!-- <p class="lead">
                                Monotonectally grow strategic process
                                improvements vis-a-vis integrated resources.
                            </p> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="row justify-content-center">
                    <div class="col-lg-4 col-md">
                        <div class="card text-center single-pricing-pack">
                            <div class="pt-4"><h5>Basic</h5></div>
                            <div class="pricing-img mt-4">
                                <img
                                    src="img/basic.svg"
                                    alt="pricing img"
                                    class="img-fluid"
                                />
                            </div>
                            <div
                                class="card-header py-4 border-0 pricing-header"
                            >
                                <div class="h1 text-center mb-0">
                                    $<span class="price font-weight-bolder"
                                        >29</span
                                    >
                                </div>
                            </div>
                            <div class="card-body">
                                <ul
                                    class="list-unstyled text-sm mb-4 pricing-feature-list"
                                >
                                    <li>Push Notifications</li>
                                    <li>Data Transfer</li>
                                    <li>SQL Database</li>
                                    <li>Search &amp; SEO Analytics</li>
                                    <li>24/7 Phone Support</li>
                                    <li>2 months technical support</li>
                                    <li>2+ profitable keyword</li>
                                </ul>
                                <a
                                    href="#"
                                    class="btn outline-btn mb-3"
                                    target="_blank"
                                    >Purchase now</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md">
                        <div
                            class="card popular-price text-center single-pricing-pack"
                        >
                            <div class="pt-4"><h5>Standard</h5></div>
                            <div class="pricing-img mt-4">
                                <img
                                    src="img/standard.svg"
                                    alt="pricing img"
                                    class="img-fluid"
                                />
                            </div>
                            <div
                                class="card-header py-4 border-0 pricing-header"
                            >
                                <div class="h1 text-center mb-0">
                                    $<span class="price font-weight-bolder"
                                        >149</span
                                    >
                                </div>
                            </div>
                            <div class="card-body">
                                <ul
                                    class="list-unstyled text-sm mb-4 pricing-feature-list"
                                >
                                    <li>Push Notifications</li>
                                    <li>Data Transfer</li>
                                    <li>SQL Database</li>
                                    <li>Search &amp; SEO Analytics</li>
                                    <li>24/7 Phone Support</li>
                                    <li>1 Year technical support</li>
                                    <li>50+ profitable keyword</li>
                                </ul>
                                <a
                                    href="#"
                                    class="btn solid-btn mb-3"
                                    target="_blank"
                                    >Purchase now</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md">
                        <div class="card text-center single-pricing-pack">
                            <div class="pt-4"><h5>Unlimited</h5></div>
                            <div class="pricing-img mt-4">
                                <img
                                    src="img/unlimited.svg"
                                    alt="pricing img"
                                    class="img-fluid"
                                />
                            </div>
                            <div
                                class="card-header py-4 border-0 pricing-header"
                            >
                                <div class="h1 text-center mb-0">
                                    $<span class="price font-weight-bolder"
                                        >39</span
                                    >
                                </div>
                            </div>
                            <div class="card-body">
                                <ul
                                    class="list-unstyled text-sm mb-4 pricing-feature-list"
                                >
                                    <li>Push Notifications</li>
                                    <li>Data Transfer</li>
                                    <li>SQL Database</li>
                                    <li>Search &amp; SEO Analytics</li>
                                    <li>24/7 Phone Support</li>
                                    <li>6 months technical support</li>
                                    <li>10+ profitable keyword</li>
                                </ul>
                                <a
                                    href="#"
                                    class="btn outline-btn mb-3"
                                    target="_blank"
                                    >Purchase now</a
                                >
                            </div>
                        </div>
                    </div>
                </div> -->
              
                <!--pricing faq start-->
                <div class="row mt-5">
                    <div class="col-lg-6">
                        <accordian id="accordion-1" :contents="contents" />
                    </div>
                    <div class="col-lg-6">
                        <accordian id="accordion-2" :contents="contents1" />
                    </div>
                </div>
                <!--pricing faq end-->

                <div class="mt-5 text-center">
                    <p class="mb-2">
                        You have more questions? Head to our
                        <a href="/faq" class="color-secondary"> FAQ</a> page.
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Accordian from "../../components/Accordian";
export default {
    name: "Pricing",
    isGray: {
        type: Boolean,
        default: false,
    },
    isPrimary: {
        type: Boolean,
        default: false,
    },
    components: {
        Accordian,
    },
    data: function () {
        return {
            contents: [
                {
                    title: "How Much does it Cost to use?",
                    description:
                        "We charge 5% for every contribution capped at 2000 NGN.",
                    active: false,
                    icon: "ti-receipt",
                },
                {
                    title: "What type of Groups are available on Trybe?",
                    description:
                        "There 2 groups on Trybe. We have the Adashi Group & the Cooperative Group. It functions like a conventional Adashi or Cooperative Society",
                    active: false,
                    icon: "ti-widget",
                },

                {
                    title: "How Can I get Started?",
                    description:
                        "Download the Trybe app from your respective stores, Create your account, invite your members & Viola! You are good to go.",
                    active: false,
                    icon: "ti-gallery",
                },
                
            ],
            contents1: [
            {
                    title: "Are there limits to the Trybe Membership?",
                    description:
                        "There are no limits on Trybe memberships & we do not charge based on memberships.",
                    active: false,
                    icon: "ti-wallet",
                },
                {
                    title: "Can I remove inactive members anytime I want?",
                    description:
                        "Yes! You have total control to invite and remove members as you wish. However, we advise you only add members you can track.",
                    active: false,
                    icon: "ti-receipt",
                },
                {
                    title: "I am only interested in the Adashi, I don't want the Cooperative?",
                    description:
                        "It is totally up to you to subscribe to the type of Group you want.",
                    active: false,
                    icon: "ti-lock",
                },
               
            ],
        };
    },
};
</script>

<style>
</style>