var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"client-section ptb-100",class:_vm.isGray ? 'gray-light-bg' : ''},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12"},[_c('carousel',{staticClass:"owl-theme clients-carousel dot-indicator owl-loaded owl-drag",attrs:{"items":1,"nav":false,"autoplay":true,"dots":true,"loop":true,"margin":15,"slideTransition":"linear","autoplayTimeout":4000,"autoplaySpeed":false,"smartSpeed":6000,"responsive":{
                        0: { items: 2 },
                        500: { items: 3 },
                        600: { items: 4 },
                        800: { items: 5 },
                        1200: { items: 6 },
                    }}},[_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-5-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-1-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-6-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-3-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-4-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-5-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-7-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-2-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-1-color.png","alt":"client logo"}})]),_c('div',{staticClass:"item single-client"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/client-3-color.png","alt":"client logo"}})])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"section-heading text-center mb-5"},[_c('h2',[_vm._v("Trusted by companies")]),_c('p',{staticClass:"lead"},[_vm._v(" Rapidiously morph transparent internal or \"organic\" sources whereas resource sucking e-business. Conveniently innovate compelling internal. ")])])])])
}]

export { render, staticRenderFns }