<template>
  <div class="main">
    <section
      class="hero-section ptb-100 background-img full-screen banner-1-bg"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-9 col-lg-7">
            <div class="hero-content-left text-white text-center">
              <h1 class="text-white">Email Verified Successfully!</h1>

              <p class="lead">Open your app to continue using Trybe</p>

              <router-link to="/">
                <button class="button btn solid-btn">Home Page</button>
              </router-link>

            
             
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ThankyouPage",
};
</script>
<style lang="scss" scoped>
.banner-1-bg {
  background: url("../assets/img/app-hero-bg.jpg") no-repeat center center /
    cover !important;
}
</style>