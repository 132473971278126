<template>
    <section id="about" class="about-us ptb-100 background-shape-img">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
          <h5 class="mt-4 color-secondary">About Trybe</h5>
          <p class="mb-4 lh-190">
           Trybe is a 360 mobile application that gives users
           a platform to build their community of savers. Through their
           community (Trybe), they can operate Cooperative and Adashi Groups.
          </p>
          <ul class="list-unstyled">
            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="badge badge-circle badge-info mr-3">
                    <span class="ti-check"></span>
                  </div>
                </div>
                <div><p class="mb-0">No onboarding or hidden charges</p></div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="badge badge-circle badge-info mr-3">
                    <span class="ti-check"></span>
                  </div>
                </div>
                <div>
                  <p class="mb-0"> Instant Transfer </p>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="badge badge-circle badge-info mr-3">
                    <span class="ti-check"></span>
                  </div>
                </div>
                <div>
                  <p class="mb-0">
                    Dynamic & Total control over all operations
                  </p>
                </div>
              </div>
            </li>

            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="badge badge-circle badge-info mr-3">
                    <span class="ti-check"></span>
                  </div>
                </div>
                <div>
                  <p class="mb-0">
                    One app to manage everything
                  </p>
                </div>
              </div>
            </li>
            <li class="py-2">
              <div class="d-flex align-items-center">
                <div>
                  <div class="badge badge-circle badge-info mr-3">
                    <span class="ti-check"></span>
                  </div>
                </div>
                <div>
                  <p class="mb-0">
                    Total Transparency of member activities of your Trybe.
                  </p>
                </div>
              </div>
            </li>
           
          
        </ul>
        </div>
              
                <!-- <div class="col-md-7">
                    <div class="about-content-left section-heading">
                        <h2>
                           Features of Trybe <br />
                          
                        </h2>

                        <div class="single-feature mb-4 mt-5">
                            <div
                                class="icon-box-wrap d-flex align-items-center mb-2"
                            >
                                <div class="mr-3 icon-box">
                                    <img
                                        src="img/image-icon-1.png"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                                <p class="mb-0">
                                   Fully customizable. Everything from contribution frequency, amount, 
                                   membership access control are open your control.
                                </p>
                            </div>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img
                                        src="img/image-icon-2.png"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                                <p class="mb-0">
                                    Instant transfer of contributions.
                                    Virtual bank account to manage your contributions.
                                    Accounts can be withdrawn from for free. 
                                </p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img
                                        src="img/image-icon-3.png"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                                <p class="mb-0">
                                   Detailed Dashboard reporting and contributions metrics.
                                   Export your data to CSV easily.
                                </p>

                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img
                                        src="img/image-icon-4.png"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                                <p class="mb-0">
                                    Free to set up and use. We only charge 5% for every contributions.
                                    No charges for funding account or withdrawals.
                                </p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div> -->

                <div class="col-md-5">
                    <div class="about-content-right">
                        <img
                            src="img/image-11.png"
                            width="350px"
                            height="625px"
                            alt="about us"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "About",
};
</script>

<style>
</style>