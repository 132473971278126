<template>
  <section class="gray-light-bg ptb-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-8">
          <div class="section-heading mb-5 text-center">
            <h2>Frequently Asked Questions</h2>
            <p class="lead">Dive deep & learn more about our solution.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="single-faq">
            <h5>What is Trybe?</h5>
            <p>
              Trybe is an application that allows you to create & manage your
              Adashi & Cooperative groups. Adashi and Cooperative are the types
              of Groups you can manage using our app.
            </p>
          </div>
        </div>

        <div class="col-md-6">
          <div class="single-faq">
            <h5>What is a Trybe?</h5>
            <p>
             A Trybe is a community of people who operate groups.
             A trybe can consist of both group type(Adashi & Cooperative)
             and members can choose to subscribe to any group of their choice. 
            </p>
          </div>
        </div>

        <div class="col-md-6">
          <div class="single-faq">
            <h5>What is an Adashi Group & How does it Work?</h5>
            <p>
              Adasi is a group where all members contribute to a single at a
              time. The circle continues until all members are paid.
             Group Admins can set order by which members are going to be paid, contribution intervals,
             contribution amount and access levels. 
            </p>
          </div>
        </div>

        <div class="col-md-6">
          <div class="single-faq">
            <h5>What is a Cooperative Group & How does it Work?</h5>
            <p>
              A Cooperative is a group where members contribute stipulated amount 
              at set intervals to  a pool.
              From pool, the admins can issue out loans to active members.
              Cooperative can also have a market-place where members can buy products
              and pay at set rules.
            </p>
          </div>
        </div>

        <div class="col-md-6">
          <div class="single-faq">
            <h5>How do I join either an Adashi or Cooperative Group?</h5>
            <p>
              To join a group; <span class="text-primary">></span> Click the "+"
              button on the bottm navigation
              <span class="text-primary">></span> Click "Join Current Trybe
              Group" bar <span class="text-primary">></span> Select the Group
              (Adashi or Cooperative) you want to join and submit.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="single-faq">
            <h5>Whose bank account are Contributions going to?</h5>
            <p>
                Bank accounts are created for users on registration.
              All contributions go to your Trybe account.
              For Adashi, admins can set if contributions go to each individual recipient or a group account.
              For Cooperative, admins can also set whose account the contributions go to.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="single-faq">
            <h5>Are contributions reflected instantly?</h5>
            <p>
              Yes. All contributions are completely instantly and
              all members receiving e-mail notifications instantly.
              All members' contributions can also be viewed on the app. 
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="single-faq">
            <h5>There is an Issue with my account, Who do I contact?</h5>
            <p>
             For complaints or inquiries, send a mail to <a href="mailto:support@thetrybe.app">support@thetrybe.app</a>
             or Call <a href="tel:+090-XXXX-XXX">090-XXXX-XXX</a> 
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FaqTwo",
};
</script>

<style>
</style>