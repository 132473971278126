<template>
    <section id="about" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6">
                    <div class="about-content-left section-heading">
                        <h2>
                            We help drive your business
                            forward faster
                        </h2>
                        <p>
                            Proactively syndicate open-source e-markets after
                            low-risk high-yield synergy. Professionally simplify
                            visionary technology before team driven sources.
                        </p>

                        <div class="single-feature mb-4">
                            <div class="d-flex align-items-center mb-2">
                                <span
                                    class="ti-vector rounded mr-3 icon icon-color-1"
                                ></span>
                                <h5 class="mb-0">Easy to use</h5>
                            </div>
                            <p>
                                Synergistically deliver next-generation
                                relationships whereas bleeding-edge resources.
                                Continually pontificate stand-alone benefits
                                whereas.
                            </p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="d-flex align-items-center mb-2">
                                <span
                                    class="ti-dashboard rounded mr-3 icon icon-color-2"
                                ></span>
                                <h5 class="mb-0">Increase conversion</h5>
                            </div>
                            <p>
                                Phosfluorescently empower compelling
                                intellectual capital and revolutionary web
                                services. Compellingly develop cross-media.
                            </p>
                        </div>

                        <div class="single-feature mb-4">
                            <div class="d-flex align-items-center mb-2">
                                <span
                                    class="ti-alarm-clock rounded mr-3 icon icon-color-3"
                                ></span>
                                <h5 class="mb-0">24/7 Support</h5>
                            </div>
                            <p>
                                Phosfluorescently matrix enterprise-wide metrics
                                vis-a-vis extensive imperatives. Energistically
                                empower best-of-breed human
                            </p>
                        </div>
                        <a href="#" class="btn solid-btn mt-2"
                            >View additional 10+ features</a
                        >
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="about-content-right">
                        <img
                            src="img/about-img.png"
                            alt="about us"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "About",
};
</script>

<style>
</style>