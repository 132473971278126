<template>
    <section id="pricing" class="package-section gray-light-bg ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <span class="badge badge-primary badge-pill"
                            >Our Pricing Package</span
                        >
                        <h2>
                            Afforadble Pricing and Packages <br />
                            choose your best one
                        </h2>
                        <p class="lead">
                            Monotonectally grow strategic process improvements
                            vis-a-vis integrated resources.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md">
                    <div class="card text-center single-pricing-pack">
                        <div class="pt-4"><h5>Basic</h5></div>
                        <div class="pricing-img mt-4">
                            <img
                                src="img/basic.svg"
                                alt="pricing img"
                                class="img-fluid"
                            />
                        </div>
                        <div class="card-header py-4 border-0 pricing-header">
                            <div class="h1 text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >29</span
                                >
                            </div>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search & SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>2 months technical support</li>
                                <li>2+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn outline-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md">
                    <div
                        class="card popular-price text-center single-pricing-pack"
                    >
                        <div class="pt-4"><h5>Standard</h5></div>
                        <div class="pricing-img mt-4">
                            <img
                                src="img/standard.svg"
                                alt="pricing img"
                                class="img-fluid"
                            />
                        </div>
                        <div class="card-header py-4 border-0 pricing-header">
                            <div class="h1 text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >149</span
                                >
                            </div>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search & SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>1 Year technical support</li>
                                <li>50+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn solid-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md">
                    <div class="card text-center single-pricing-pack">
                        <div class="pt-4"><h5>Unlimited</h5></div>
                        <div class="pricing-img mt-4">
                            <img
                                src="img/unlimited.svg"
                                alt="pricing img"
                                class="img-fluid"
                            />
                        </div>
                        <div class="card-header py-4 border-0 pricing-header">
                            <div class="h1 text-center mb-0">
                                $<span class="price font-weight-bolder"
                                    >39</span
                                >
                            </div>
                        </div>
                        <div class="card-body">
                            <ul
                                class="list-unstyled text-sm mb-4 pricing-feature-list"
                            >
                                <li>Push Notifications</li>
                                <li>Data Transfer</li>
                                <li>SQL Database</li>
                                <li>Search & SEO Analytics</li>
                                <li>24/7 Phone Support</li>
                                <li>6 months technical support</li>
                                <li>10+ profitable keyword</li>
                            </ul>
                            <a
                                href="#"
                                class="btn outline-btn mb-3"
                                target="_blank"
                                >Purchase now</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center">
                <p class="mb-2">
                    If you need custom services or Need more?
                    <a href="#" class="color-secondary"> Contact us </a>
                </p>
            </div>
            <!--faq start-->
            <div class="row pt-5">
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>How can I pay for this?</h5>
                        <p>
                            Intrinsicly implement high standards in strategic
                            theme areas via inexpensive solutions. Assertively
                            conceptualize prospective bandwidth whereas
                            client-based imperatives.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>Is it possible to pay yearly?</h5>
                        <p>
                            Assertively iterate user friendly innovation without
                            open-source markets. Monotonectally extend resource
                            sucking manufactured products without high-payoff
                            paradigms. Objectively customize ubiquitous
                            information before economically sound relationships.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>Do you offer discounts on multiple items?</h5>
                        <p>
                            Dramatically target focused testing procedures after
                            holistic ideas. Collaboratively maximize high-payoff
                            ROI and value-added products. Distinctively deliver
                            cooperative collaboration and idea-sharing whereas
                            customized
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>Is VAT included in plan prices?</h5>
                        <p>
                            Distinctively simplify high-quality initiatives for
                            highly efficient applications. Monotonectally
                            repurpose integrated customer service after magnetic
                            e-services.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>Will I pay more for some features?</h5>
                        <p>
                            Enthusiastically pontificate resource-leveling
                            supply chains whereas scalable markets.
                            Authoritatively streamline resource maximizing
                            methods of empowerment
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>
                            Why are there no limits on the number of messages?
                        </h5>
                        <p>
                            Assertively target turnkey ideas for market-driven
                            portals. Appropriately e-enable world-class
                            intellectual capital whereas 2.0 mindshare.
                        </p>
                    </div>
                </div>
            </div>
            <!--faq end-->
        </div>
    </section>
</template>

<script>
export default {
    name: "Pricing",
};
</script>

<style>
</style>