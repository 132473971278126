var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"screenshots-section ptb-100",class:_vm.isGray ? 'gray-light-bg' : 'primary-bg',attrs:{"id":"screenshots"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"section-heading text-center"},[_c('h2',{class:_vm.isGray ? '' : 'text-white'},[_vm._v(" App screenshots "),_c('br'),_vm._v(" Looks awesome ")]),_c('p',{staticClass:"lead"},[_vm._v(" Credibly synthesize multimedia based networks vis-a-vis top-line growth strategies. Continually leverage existing worldwide interfaces ")])])])]),_c('div',{staticClass:"screen-slider-content mt-5"},[_c('div',{staticClass:"screenshot-frame"}),_c('carousel',{staticClass:"screen-carousel owl-carousel owl-theme dot-indicator owl-loaded owl-drag",attrs:{"autoplay":true,"dots":true,"loop":true,"responsive":{
                    0: { items: 1 },
                    768: { items: 3 },
                    991: { items: 4 },
                    1200: { items: 4 },
                    1920: { items: 4 },
                },"margin":0,"center":true,"nav":false}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"img/01.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"img/03.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"img/02.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"img/04.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"img/05.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"img/06.png","alt":"screenshots"}})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }