<template>
    <section class="promo-section ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-9">
                    <div class="section-heading mb-5">
                        <h2>Frequently Asked Questions</h2>
                        <p class="lead">
                            Quickly morph client-centric results through
                            performance based applications. Proactively
                            facilitate professional human capital for
                            cutting-edge.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <accordian id="accordion-1" :contents="contents" />
                </div>
                <div class="col-lg-6">
                    <accordian id="accordion-2" :contents="contents1" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Accordian from "../../components/Accordian";
export default {
    name: "FaqOne",
    components: {
        Accordian,
    },
    data: function () {
        return {
          contents: [
                {
                    title: "How Much does it Cost to use?",
                    description:
                        "We charge 5% for every contribution capped at 2000 NGN.",
                    active: false,
                    icon: "ti-receipt",
                },
                {
                    title: "What type of Groups are available on Trybe?",
                    description:
                        "There 2 groups on Trybe. We have the Adashi Group & the Cooperative Group. It functions like a conventional Adashi or Cooperative Society",
                    active: false,
                    icon: "ti-widget",
                },

                {
                    title: "How Can I get Started?",
                    description:
                        "Download the Trybe app from your respective stores, Create your account, invite your members & Viola! You are good to go.",
                    active: false,
                    icon: "ti-gallery",
                },
                
            ],
            contents1: [
            {
                    title: "Are there limits to the Trybe Membership?",
                    description:
                        "There are no limits on Trybe memberships & we do not charge based on memberships.",
                    active: false,
                    icon: "ti-wallet",
                },
                {
                    title: "Can I remove inactive members anytime I want?",
                    description:
                        "Yes! You have total control to invite and remove members as you wish. However, we advise you only add members you can track.",
                    active: false,
                    icon: "ti-receipt",
                },
                {
                    title: "I am only interested in the Adashi, I don't want the Cooperative?",
                    description:
                        "It is totally up to you to subscribe to the type of Group you want.",
                    active: false,
                    icon: "ti-lock",
                },
               
            ],
        };
    },
};
</script>

<style>
</style>