var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"single-blog-card card border-0 shadow-sm",class:{
        'shadow-sm': _vm.isGray && !_vm.isPrimary,
        'white-bg': !_vm.isGray && _vm.isPrimary,
    }},[_c('span',{staticClass:"category position-absolute badge badge-pill",class:{
            'badge-primary':
                _vm.isCategoryPrimary &&
                !_vm.isCategoryWarning &&
                !_vm.isCategoryDanger,
            'badge-danger':
                _vm.isCategoryDanger &&
                !_vm.isCategoryWarning &&
                !_vm.isCategoryPrimary,
            'badge-warning':
                _vm.isCategoryWarning &&
                !_vm.isCategoryPrimary &&
                !_vm.isCategoryDanger,
        }},[_vm._v(_vm._s(_vm.categories))]),_c('img',{staticClass:"card-img-top position-relative",attrs:{"src":_vm.imageUrl,"alt":"blog"}}),_c('div',{staticClass:"meta-date card-body"},[_c('div',{staticClass:"post-meta mb-2"},[_c('ul',{staticClass:"list-inline meta-list"},[_c('li',{staticClass:"list-inline-item"},[_c('small',[_vm._v(_vm._s(_vm.month)+" ")]),_c('small',[_vm._v(_vm._s(_vm.day)+", ")]),_c('small',[_vm._v(_vm._s(_vm.year))])]),_c('li',{staticClass:"list-inline-item"},[_c('span',[_vm._v(_vm._s(_vm.comments))]),_vm._v(" Comments ")]),_c('li',{staticClass:"list-inline-item"},[_c('span',[_vm._v(_vm._s(_vm.shares))]),_vm._v(" Share ")])])]),_c('h3',{staticClass:"h5 card-title"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.title))])]),_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.desc)+" ")]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"detail-link",attrs:{"href":"#"}},[_vm._v(" Read more "),_c('span',{staticClass:"ti-arrow-right"})])
}]

export { render, staticRenderFns }