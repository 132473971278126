<template>
    <section id="features" class="feature-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <span
                            v-if="showBadge"
                            class="badge badge-primary badge-pill"
                            >Best features</span
                        >
                        <h2>
                          Our Features
                        </h2>
                        <p>
                           Trybe is fully equipped to handle all your
                           social saving needs and we are improving it daily.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row row-grid align-items-center">
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-1 rounded-circle"
                            >
                                <span class="las la-people-carry"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Adashi Management</h5>
                            <p class="mb-0">
                                Modular and interchangable componente between
                                layouts and even demos.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-2 rounded-circle"
                            >
                                <!-- <span class="ti-vector"></span> -->
                                <span class="las la-users"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Cooperative Group Management</h5>
                            <p class="mb-0">
                                Modular and interchangable componente between
                                layouts and even demos.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-3 rounded-circle"
                            >
                                <span class="ti-shopping-cart"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Market Place System</h5>
                            <p class="mb-0">
                               Manage marketplace in your cooperative and sell products to your members.
                               Memebers pay at agreed set duration.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="position-relative" style="z-index: 10">
                        <img
                            alt="Image placeholder"
                            src="img/image-14.png"
                            class="img-center img-fluid"
                        />
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-4 rounded-circle"
                            >
                                <span class="ti-layout-media-right"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Cost Efficient</h5>
                            <p class="mb-0">
                               We only charge a fee of <span class="color-secondary">5%</span> of contribution amount 
                               capped at  <span class="color-secondary"> 2,000 NGN</span>.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mb-5">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-5 rounded-circle"
                            >
                                <span class="ti-layout-cta-right"></span>
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Transparency & Data Privacy</h5>
                            <p class="mb-0">
                               All Contributions & Payments are tracked by Trybe members 
                             through instant multi-channel notifications.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-start">
                        <div class="pr-4">
                            <div
                                class="icon icon-shape icon-color-6 rounded-circle"
                            >
                                <span class="ti-credit-card"></span>

                               
                            </div>
                        </div>
                        <div class="icon-text">
                            <h5>Loan Management</h5>
                            <p class="mb-0">
                              Give out loans to your members and set payment plans for them.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: "Features",
};
</script>

<style>
</style>