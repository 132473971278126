<template>
    <section class="team-single-section ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 col-sm-12 col-lg-5">
                    <div class="team-single-img">
                        <img
                            src="img/team-1.jpg"
                            alt="member"
                            class="img-fluid rounded shadow-sm"
                        />
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="team-single-text">
                        <div class="team-name mb-4">
                            <h4 class="mb-1">Henry Ford</h4>
                            <span>Managing Director</span>
                        </div>
                        <ul class="team-single-info">
                            <li>
                                <strong>Phone:</strong
                                ><span> (+123) 123-456-789</span>
                            </li>
                            <li>
                                <strong>Email:</strong
                                ><span> yourname@domain.com</span>
                            </li>
                        </ul>
                        <div class="text-content mt-20">
                            <p>
                                Authoritatively deploy fully researched
                                leadership skills whereas one-to-one best
                                practices. Monotonectally aggregate virtual
                                imperatives and accurate technology. Dynamically
                                streamline progressive sources before user
                                friendly.
                            </p>
                            <p>
                                Enthusiastically synthesize cross-unit
                                technology for sticky results.
                            </p>
                            <p>
                                Progressively productize vertical alignments
                                after sticky process improvements. Competently
                                scale transparent methods of empowerment and
                                visionary products.
                            </p>
                        </div>
                        <ul class="team-social-list list-inline mt-4">
                            <li class="list-inline-item">
                                <a href="#" class="color-primary"
                                    ><span class="ti-facebook"></span
                                ></a>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <a href="#" class="color-primary"
                                    ><span class="ti-instagram"></span
                                ></a>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <a href="#" class="color-primary"
                                    ><span class="ti-dribbble"></span
                                ></a>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <a href="#" class="color-primary"
                                    ><span class="ti-linkedin"></span
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-6 col-sm-6 col-12">
                    <div class="section-heading">
                        <h5>Activities And Skills</h5>
                        <div class="section-heading-line-left"></div>
                    </div>
                    <ul class="list-unstyled">
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">
                                        Good Business Consultants are Excellent
                                        Communicators
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">
                                        At the End of a Project, Good Business
                                        Consultants Can “Let it Go”
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">
                                        Good Business Consultants are Willing to
                                        Travel Where the Work Is
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="badge badge-primary mr-3">
                                        <span class="ti-check"></span>
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0">
                                        Write a Business Plan that Clearly
                                        Articulates the Following
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6 col-sm-6 col-12">
                    <div class="section-heading mt-40">
                        <h5>Professional Skills</h5>
                        <div class="section-heading-line-left"></div>
                    </div>
                    <div class="mt-3">
                        <div class="progress-item">
                            <div class="progress-title">
                                <h6>
                                    Web Development<span class="float-right"
                                        ><span class="progress-number">60</span
                                        >%</span
                                    >
                                </h6>
                            </div>
                            <div class="progress p-1">
                                <span style="width: 60%"
                                    ><span class="progress-line"></span
                                ></span>
                            </div>
                        </div>
                        <div class="progress-item">
                            <div class="progress-title">
                                <h6>
                                    User Experience<span class="float-right"
                                        ><span class="progress-number">80</span
                                        >%</span
                                    >
                                </h6>
                            </div>
                            <div class="progress p-1">
                                <span style="width: 80%"
                                    ><span class="progress-line"></span
                                ></span>
                            </div>
                        </div>
                        <div class="progress-item">
                            <div class="progress-title">
                                <h6>
                                    Front-End Framework<span class="float-right"
                                        ><span class="progress-number">50</span
                                        >%</span
                                    >
                                </h6>
                            </div>
                            <div class="progress p-1">
                                <span style="width: 50%"
                                    ><span class="progress-line"></span
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "TeamSingle",
};
</script>

<style>
</style>